import { CreditCardFilled } from '@ant-design/icons';
import { AiFillTags, AiFillTool } from 'react-icons/ai';
import { BsBoxSeam } from "react-icons/bs";
import { FaBook, FaEdit, FaLanguage, FaUsers, FaUserTie, FaWarehouse } from 'react-icons/fa';
import { HiSpeakerphone } from 'react-icons/hi';
import { RiBarChart2Fill, RiShoppingBag2Fill, RiMenu2Line } from "react-icons/ri";



const icons = {
  dashboard: <RiBarChart2Fill />,
  manufacturers: <RiShoppingBag2Fill />,
  products: <BsBoxSeam />,
  orders: <CreditCardFilled />,
  customers: <FaUsers />,
  employees: <FaUserTie />,
  navigation: <RiMenu2Line />,
  tools: <AiFillTool />,
  'hk-warehouse': <FaWarehouse />,
  'push-notifications': <HiSpeakerphone />,
  priceTag: <AiFillTags />,
  productsNew: <FaBook />,
  manufacturersNew: <FaEdit />,
  transalte: <FaLanguage />,
}

export default icons